import { Injectable } from '@angular/core';
import { ToastController, AlertController, NavController, MenuController, ActionSheetController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';

const headersPost = new HttpHeaders({
  'Content-Type': 'application/x-www-form-urlencoded'
});

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  loader;

  //private uri = "http://127.0.0.1:3000/api";
  private uri = "https://api.rbapps.top/api";

  getDataWebsite() {
    return { "website": { } }
  }

  public dadosApp = {} as any;
  public dadosWebUser = {} as any;
  public token;
  public data = {} as any;

  constructor( 
    public navCtrl: NavController,
    public loadingController: LoadingController,
    public toastCtrl: ToastController,
    public http: HttpClient,
    public alertCtrl: AlertController,
    public _router: Router,
    public menu: MenuController,
    public actionSheetController: ActionSheetController,
    public storage: Storage,
    public route: ActivatedRoute,
  ) { }

  setData(d) {
    this.dadosWebUser = d;
  }

  saveStorage(){
    this.storage.remove('website').then($=>{
      this.storage.set('website', {website: this.website(), dataUser: this.user()});
    })
  }

  user() {
    if (!this.getToken()) return {};
    if (!this.dadosWebUser) return {};
    if (this.dadosWebUser.dataUser) {
      return this.dadosWebUser.dataUser;
    }
    return {};
  }

  getToken() {
    if(this.token){
      return this.token;
    }else{
      return "";
    }
  }

  setToken(token) {
    this.token = token;
  }

  website(){
    if(this.dadosWebUser.website){
      return this.dadosWebUser.website;
    }else{
      return {}
    }
  }

  getDataStorage(){
    return new Promise((resolve, reject) => {
      Promise.all([this.storage.get("email"), this.storage.get("password"), this.storage.get("token"), this.storage.get("website"), this.storage.get("date_time"), this.storage.get("dataUser")]).then((values: any) => {
        resolve(values)
      });
    });
  }

  setDados(element) {
    this.dadosApp = element;
  }

  idTime() {
    //return "5e0661957c213e47b9c3fa60"; //Gremio
    return "5e079c0d59eb8d2f28ee31bd"; //Flamengo
    //return "5e079c4c59eb8d2f28ee31be"; //Corinthians
    //return "5e079c8159eb8d2f28ee31c0"; //Palmeiras
    //return "5e079c6959eb8d2f28ee31bf"; //Santos
    //return "5e079c9c59eb8d2f28ee31c1"; //São Paulo
  }


  getQuery(query) {
    var q = "token=" + this.getToken() + "&";
    if (!query) return q;
    Object.keys(query).forEach((key) => {
      q += key + "=" + query[key] + "&";
    })
    return q;
  }

  get(local, query) {

    return new Promise((resolve, reject) => {
      this.http.get(this.uri + local + '?' + this.getQuery(query)).subscribe(($: any) => {
        resolve($);
      }, (err) => {
        let error = err.error;
        if (error && error.message) return this.presentAlert(error.message);
      })
    })

  }

  post(local, body) {

    return new Promise((resolve, reject) => {
      this.http.post(this.uri + local, this.getQuery(body), { headers: headersPost }).subscribe(($: any) => {

        resolve($);
      }, (err) => {
        let error = err.error;
        if (error && error.message){ 
          return this.presentToast(error.message)
        }
      })

    })

  }

  saveFile(local, body) {

    return new Promise((resolve, reject) => {

      this.http.post(this.uri + local, body).subscribe(res => {

        resolve(res);
      }, (err) => {

        reject(err.error);
      });
    })

  }

  async presentAlert(msg) {
    const alert = await this.alertCtrl.create({
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  async alertAtt(title, msg, link, textBtn) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: [
        {
          text: textBtn,
          handler: () => {
            window.open(link)
          }
        }
      ]
    });

    await alert.present();
  }

  async alertAttM(title, msg, link, textBtn) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: [
        {
          text: textBtn,
          handler: () => {
            this.navCtrl.navigateForward(link)
          }
        }
      ]
    });

    await alert.present();
  }

  async presentToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
    });
    toast.present();
  }

  async presentLoading(msg) {
    this.loader = await this.loadingController.create({
      message: msg,
      cssClass: 'my-custom-class'
    });

    this.loader.present();
  }

  closeLoading() {
    this.loader.dismiss();
  }

}