import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/guard/guard';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'sites',
    loadChildren: () => import('./pages/sites/sites.module').then( m => m.SitesPageModule)
  },
  {
    path: 'campeonatos',
    loadChildren: () => import('./pages/campeonatos/campeonatos.module').then( m => m.CampeonatosPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'jogos',
    loadChildren: () => import('./pages/jogos/jogos.module').then( m => m.JogosPageModule)
  },
  {
    path: 'noticias',
    loadChildren: () => import('./pages/noticias/noticias.module').then( m => m.NoticiasPageModule)
  },
  {
    path: 'times',
    loadChildren: () => import('./pages/times/times.module').then( m => m.TimesPageModule)
  },
  {
    path: 'createjogo',
    loadChildren: () => import('./pages/create/jogos/jogos.module').then( m => m.JogosPageModule)
  },
  {
    path: 'createnews',
    loadChildren: () => import('./pages/create/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'createtime',
    loadChildren: () => import('./pages/create/times/times.module').then( m => m.TimesPageModule)
  },
  {
    path: 'createcampeonato',
    loadChildren: () => import('./pages/create/campeonatos/campeonatos.module').then( m => m.CampeonatosPageModule)
  },
  {
    path: 'createnotificacao',
    loadChildren: () => import('./pages/create/notificacao/notificacao.module').then( m => m.NotificacaoPageModule)
  },
  {
    path: 'createsite',
    loadChildren: () => import('./pages/create/sites/sites.module').then( m => m.SitesPageModule)
  },
  {
    path: 'editcampeonato/:id',
    loadChildren: () => import('./pages/edit/campeonatos/campeonatos.module').then( m => m.CampeonatosPageModule)
  },
  {
    path: 'editjogo/:id',
    loadChildren: () => import('./pages/edit/jogos/jogos.module').then( m => m.JogosPageModule)
  },
  {
    path: 'tabelas',
    loadChildren: () => import('./pages/tabelas/tabelas.module').then( m => m.TabelasPageModule)
  },
  {
    path: 'createtabela',
    loadChildren: () => import('./pages/create/tabelas/createtabela.module').then( m => m.CreatetabelaPageModule)
  },
  {
    path: 'edittabela/:id',
    loadChildren: () => import('./pages/edit/tabelas/editetabela.module').then( m => m.EditetabelaPageModule)
  },
  {
    path: 'edittime/:id',
    loadChildren: () => import('./pages/edit/times/times.module').then( m => m.TimesPageModule)
  },
  {
    path: 'moderadores',
    loadChildren: () => import('./pages/moderadores/moderadores.module').then( m => m.ModeradoresPageModule)
  },
  {
    path: 'createmoderador',
    loadChildren: () => import('./pages/create/createmoderador/createmoderador.module').then( m => m.CreatemoderadorPageModule)
  },
  {
    path: 'editemoderador',
    loadChildren: () => import('./pages/edit/editemoderador/editemoderador.module').then( m => m.EditemoderadorPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
