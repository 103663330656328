import { NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor( private api: ApiService, public navCtrl: NavController) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {

        return new Promise((resolve, reject) => {

            if (this.api.dadosApp.website) {
                return resolve(true);
            }

            Promise.all([this.api.storage.get("email"), this.api.storage.get("password"), this.api.storage.get("token"), this.api.storage.get("website"), this.api.storage.get("date_time"), this.api.storage.get("dataUser")]).then((values: any) => {

                if (!values[2]) {
                    this.api._router.navigate(['/login'])
                } else {


                    console.log(values)

                    this.api.setToken(values[2]);

                    if (values[3] && values[4]) { //is website e time
                        let atual = new Date().getTime();
                        if (atual < values[4]) {
                            this.api.setData(values[3]);
                            this.start();
                            console.log('token n expirou e dados ok')
                            return resolve(true);
                        }
                    }

                    if (values[5]) { //is userData
                        this.api.setData(values[5]);
                    }

                    if (!this.api.website()) {
                        this.api.setData(values[3] ? values[3] : this.api.getDataWebsite());
                        this.api.setData(values[5]);
                        this.api.saveStorage();
                    }

                    if (!this.api.user()._id && !values[0]) {
                        console.log('user não esta logado e n existe email')
                        this.start();
                        resolve(true);
                    } else if (this.api.user()._id || !values[0]) {
                        console.log('user esta logado ou n existe email')
                        this.start();
                        resolve(true);
                    } else {
                        console.log('token expirou tempo e buscando dados user')

                        this.api.post('/users/login', { email: values[0], password: values[1] }).then((res: any) => {
                            if (res && res.dados) {

                                this.api.dadosWebUser.dataUser = res.dados.userData;
                                this.api.setToken(res.token);
                                this.api.saveStorage();
                                this.api.storage.set('token', res.token);

                                let date = new Date();
                                date.setHours(24);

                                this.api.storage.set('date_time', date.getTime());

                                this.start();

                            } else {
                                this.api.storage.remove('email');
                                this.api.storage.remove('password');
                                this.api.storage.remove('token');
                            }
                            this.start();
                            resolve(true);
                        });
                    }
                }

            });

        })

    }

    start() {
    }

}